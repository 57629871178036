import { BrandDTO, BrandDtoV2, PriceDto, SubscriptionDto } from "@/dtos";
import { baseApi, baseApiV2 } from "./base-api.slice";
import { Subscription } from "@paddle/paddle-node-sdk";

const baseApiWithTags = baseApi.enhanceEndpoints({ addTagTypes: ["Brand"] });

const brandsApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<{ brands: BrandDTO[] }, void>({
      query: () => "/brands",
      providesTags: ["Brand"],
    }),
    getBrandById: builder.query<{ brand: BrandDTO }, { id: string }>({
      query: ({ id }) => `/brands/${id}`,
      providesTags: ["Brand"],
    }),
    getBrandSubscriptionByBrandId: builder.query<
      { subscription: Partial<Subscription> },
      { brandId: string }
    >({
      query: ({ brandId }) => `/brands/${brandId}/subscription`,
      providesTags: ["Brand"],
    }),
    createBrand: builder.mutation<
      void,
      { name: string; mission: string; homepage: string }
    >({
      query: (body) => ({
        url: "/brands",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Brand"],
    }),
  }),
});

const brandApiWithTagsV2 = baseApiV2.enhanceEndpoints({
  addTagTypes: ["BrandV2"],
});

const brandsApiV2 = brandApiWithTagsV2.injectEndpoints({
  endpoints: (builder) => ({
    getBrandsV2: builder.query<BrandDtoV2[], void>({
      query: () => "/brands",
      providesTags: ["BrandV2"],
    }),
    getBrandByIdV2: builder.query<BrandDtoV2, { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}`,
      providesTags: ["BrandV2"],
    }),
    getBrandPrices: builder.query<PriceDto[], { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}/prices`,
      providesTags: ["BrandV2"],
    }),
    createBrandCheckoutSession: builder.mutation<
      { url: string },
      { brandId: string; priceId: string }
    >({
      query: ({ brandId, priceId }) => ({
        url: `/brands/${brandId}/checkout-session`,
        method: "POST",
        body: { priceId },
      }),
    }),
    getBrandSubscription: builder.query<SubscriptionDto, { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}/subscription`,
      providesTags: ["BrandV2"],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetBrandByIdQuery,
  useCreateBrandMutation,
} = brandsApi;

export const {
  useGetBrandsV2Query,
  useGetBrandByIdV2Query,
  useGetBrandPricesQuery,
  useCreateBrandCheckoutSessionMutation,
  useGetBrandSubscriptionQuery,
  useLazyGetBrandByIdV2Query,
} = brandsApiV2;

export const { invalidateTags: invalidateBrandsTags } = brandsApi.util;
