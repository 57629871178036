import { useBrandContext } from "@/hooks";
import { useGetBrandByIdV2Query } from "@/redux";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const UpgradeCallbackPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: brand, refetch } = useGetBrandByIdV2Query(
    {
      brandId: searchParams.get("brandId") ?? "",
    },
    {
      skip: !searchParams.get("brandId"),
    }
  );
  const { setBrand } = useBrandContext();

  useEffect(() => {
    if (!brand) return;
    setBrand(brand);
  }, [brand, setBrand]);

  useEffect(() => {
    const success = searchParams.get("success") === "true";
    const canceled = searchParams.get("canceled") === "true";

    if (canceled) {
      navigate("/upgrade");
    }

    if (success) {
      const pollBrand = async () => {
        await refetch();
        if (brand?.subscription?.status === "active") {
          navigate("/settings/billing");
        } else {
          setTimeout(pollBrand, 2000); // Poll every 2 seconds
        }
      };
      pollBrand();
    }
  }, [location, navigate, searchParams, brand, refetch]);

  return <div>Processing your upgrade...</div>;
};
