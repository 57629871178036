import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { logout } from "./auth.slice";

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401 || result.error?.status === 403) {
    api.dispatch(logout());
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithAuth,
  endpoints: () => ({}),
});

const baseQueryV2 = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_V2_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryV2WithAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await baseQueryV2(args, api, extraOptions);
  if (result.error?.status === 401 || result.error?.status === 403) {
    api.dispatch(logout());
  }
  return result;
};

export const baseApiV2 = createApi({
  reducerPath: "baseApiV2",
  baseQuery: baseQueryV2WithAuth,
  endpoints: () => ({}),
});
