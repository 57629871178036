import { ChevronsUpDown, Plus, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { BrandDtoV2 } from "@/dtos";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import { useBrandContext } from "@/hooks";
import { useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export function BrandSwitcher({
  brands,
  openBrandForm,
}: {
  brands: BrandDtoV2[];
  openBrandForm: () => void;
}) {
  const { isMobile, state } = useSidebar();
  const { brand, setBrand } = useBrandContext();
  const [open, setOpen] = useState(false);

  const isCollapsed = state === "collapsed";

  const avatar = createAvatar(identicon, {
    seed: brand?.name,
    size: 32,
  });

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className={`w-full justify-between data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground ${
                isCollapsed ? "px-2" : ""
              }`}
            >
              <div
                className={`flex items-center ${
                  isCollapsed ? "justify-center" : "gap-3"
                } overflow-hidden w-full`}
              >
                <Avatar className="h-6 w-6 rounded-lg">
                  <AvatarImage
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      avatar.toString()
                    )}`}
                    alt={brand?.name}
                  />
                  <AvatarFallback className="rounded-lg">
                    <User className="h-full w-full text-gray-400" />
                  </AvatarFallback>
                </Avatar>

                {!isCollapsed && (
                  <div className="flex flex-col items-start overflow-hidden">
                    <span className="font-medium truncate w-full">
                      {brand?.name}
                    </span>
                    <span className="text-xs text-muted-foreground">
                      {brand?.quotas?.content?.limit === -1 ? "Basic" : "Free"}
                    </span>
                  </div>
                )}
              </div>
              {!isCollapsed && (
                <ChevronsUpDown className="h-4 w-4 opacity-50 flex-shrink-0 ml-2" />
              )}
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-56"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={8}
          >
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium leading-none">Brands</p>
                <p className="text-xs leading-none text-muted-foreground">
                  Select or add a brand
                </p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {brands.map((brand, index) => (
              <DropdownMenuItem key={brand.id} onClick={() => setBrand(brand)}>
                <div className="flex items-center gap-3 overflow-hidden">
                  <Avatar className="h-6 w-6 rounded-lg">
                    <AvatarImage
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        createAvatar(identicon, {
                          seed: brand.name,
                          size: 32,
                        }).toString()
                      )}`}
                      alt={brand?.name}
                    />
                    <AvatarFallback className="rounded-lg">
                      <User className="h-full w-full text-gray-400" />
                    </AvatarFallback>
                  </Avatar>
                  <span className="truncate">{brand.name}</span>
                </div>
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                setOpen(false);
                openBrandForm();
              }}
            >
              <Plus className="mr-2 h-4 w-4 flex-shrink-0" />
              <span className="truncate">New brand</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
