import { Flex } from "@radix-ui/themes";
import { useBrandContext } from "@/hooks";
import { useNavigate } from "react-router-dom";

import { ContentCard, ContentErrors } from "@/components";
import {
  useArchiveContentMutation,
  useGetContentQuery,
  useLazyGetBrandByIdV2Query,
} from "@/redux";
import { Archive, Pencil } from "lucide-react";
import { useEffect } from "react";

export const ContentDrafts = (): JSX.Element => {
  const { brand, setBrand } = useBrandContext();

  const [getBrand] = useLazyGetBrandByIdV2Query();

  const {
    data: content,
    error: contentLoadingError,
    refetch: reloadContent,
  } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand?.id,
      pollingInterval: 10000,
    }
  );

  const [archiveContent] = useArchiveContentMutation();

  const navigate = useNavigate();

  const handleArchiveContent = async ({ contentId }: { contentId: string }) => {
    await archiveContent({ brandId: brand?.id || "", contentId });
    reloadContent();
  };

  // Refetch brand data whenever there is new content so we can check whether to display the upgrade card or not
  useEffect(() => {
    if (!brand) return;

    getBrand({ brandId: brand.id }).then((res) => {
      if (res.data) {
        setBrand(res.data);
      }
    });
  }, [content, brand, getBrand, setBrand]);

  const drafts = content?.filter(
    (c) =>
      c.status === "draft" ||
      c.status === "researching" ||
      c.status === "generating" ||
      c.status === "generating_images" ||
      c.status === "images_failed" ||
      c.status === "failed"
  );

  return (
    <>
      <Flex justify="between" pb="6">
        <h1 className="text-lg font-semibold md:text-2xl">Drafts</h1>
      </Flex>

      <ContentErrors
        error={
          contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : contentLoadingError && "error" in contentLoadingError
            ? contentLoadingError.error
            : undefined
        }
      />

      <Flex height="80%">
        {(!drafts || drafts?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no drafts yet
            </h3>
          </div>
        )}

        {drafts && drafts?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%" pb="4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {drafts.map((draft) => (
                  <ContentCard
                    key={draft.id}
                    content={draft}
                    onClick={() => {
                      navigate(`/brands/${brand?.id}/content/${draft.id}`);
                    }}
                    actions={[
                      {
                        name: "Edit",
                        onClick: () =>
                          navigate(`/brands/${brand?.id}/content/${draft.id}`),
                        icon: Pencil,
                        iconClassName: "text-blue-500",
                      },
                      {
                        name: "Archive",
                        onClick: () =>
                          handleArchiveContent({ contentId: draft.id }),
                        icon: Archive,
                        iconClassName: "text-red-500",
                      },
                    ]}
                  />
                ))}
              </div>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
