import { CalendarComponent, CalendarEvent } from "@/components";
import { useBrandContext } from "@/hooks";
import { useGetContentQuery, useGetWritersQuery } from "@/redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ContentSchedule = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { data } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand,
      pollingInterval: 10000,
    }
  );
  const { data: writers } = useGetWritersQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand,
    }
  );
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (!data || !writers) return;

    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    // Transform writer schedules into events
    const writerEvents =
      writers?.flatMap((writer) => {
        const daysOfWeek = writer.daysOfWeek.map((day) => day.toLowerCase());
        const hoursOfDay = writer.hoursOfDay;
        const minutesOfHour = writer.minutesOfHour;

        const upcoming: CalendarEvent[] = [];

        // Get next 6 months of dates
        const today = new Date();
        const sixMonthsFromNow = new Date(today);
        sixMonthsFromNow.setMonth(today.getMonth() + 6);

        // For each day between now and 6 months from now
        const currentDate = new Date(today);
        while (currentDate <= sixMonthsFromNow) {
          const dayOfWeek = days[currentDate.getDay()];

          // Check if writer works this day
          if (daysOfWeek.includes(dayOfWeek)) {
            // For each hour they work
            hoursOfDay.forEach((hour) => {
              // For each minute they work
              minutesOfHour.forEach((minute) => {
                const eventDate = new Date(currentDate);
                eventDate.setHours(hour, minute, 0, 0);

                // Only add future events
                if (eventDate > today) {
                  upcoming.push({
                    id: `writer-${writer.id}-${eventDate.getTime()}`,
                    date: eventDate,
                    title: `Scheduled for ${writer.name}`,
                    type: writer.contentType,
                  });
                }
              });
            });
          }

          // Move to next day
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return upcoming;
      }) ?? [];

    const contentEvents =
      data
        ?.filter((content) => content.targetDate)
        ?.filter((content) => new Date(content.targetDate!) > new Date())
        .map((content) => ({
          id: content.id,
          date: new Date(content.targetDate!),
          title: content.brief,
          thumbnail: content.versions?.[0]?.posts?.[0]?.thumbnail,
          type: content.type,
        })) ?? [];

    setEvents([...contentEvents, ...writerEvents]);
  }, [data, writers]);

  const navigate = useNavigate();

  return (
    <CalendarComponent
      events={events}
      onEventClick={(event) => {
        if (event.id.includes("writer-")) {
          navigate(`/automation/writers`);
        } else {
          navigate(`/brands/${brand?.id}/content/${event.id}`);
        }
      }}
    />
  );
};
