import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useBrandContext } from "@/hooks";
import {
  useCreateBrandCheckoutSessionMutation,
  useGetBrandPricesQuery,
} from "@/redux";
import { Flex, Text } from "@radix-ui/themes";
import { CreditCard } from "lucide-react";

export const UpgradePage = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { data: prices } = useGetBrandPricesQuery(
    { brandId: brand?.id || "" },
    { skip: !brand?.id }
  );
  const [createBrandCheckoutSession, { isLoading: isCreatingCheckoutSession }] =
    useCreateBrandCheckoutSessionMutation();

  const currencyCodeToSymbol = (currency: string): string => {
    switch (currency) {
      case "gbp":
        return "£";
      case "usd":
        return "$";
      case "eur":
        return "€";
      default:
        return currency;
    }
  };

  const handleUpgrade = async (priceId: string) => {
    const result = await createBrandCheckoutSession({
      brandId: brand?.id || "",
      priceId,
    }).unwrap();
    window.location.href = result.url;
  };

  if (!brand || !prices) {
    return (
      <Flex direction="column" gap="4">
        <Flex justify="between" pb="3">
          <h1 className="text-lg font-semibold md:text-2xl">
            Upgrade Your Plan
          </h1>
        </Flex>
        <Flex height="80%" direction="column">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[1, 2].map((_, index) => (
              <div
                key={index}
                className="border rounded-lg p-6 shadow-md animate-pulse"
              >
                <Skeleton className="h-6 w-3/4 mb-4" />
                <Skeleton className="h-8 w-1/2 mb-4" />
                <div className="space-y-2 mb-6">
                  {[1, 2, 3].map((_, i) => (
                    <Skeleton key={i} className="h-4 w-full" />
                  ))}
                </div>
                <Skeleton className="h-10 w-full" />
              </div>
            ))}
          </div>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="4">
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Upgrade Your Plan</h1>
      </Flex>
      <Flex height="80%" direction="column">
        {prices.length === 0 ? (
          <div className="flex flex-1 flex-col items-center justify-center gap-6 text-center p-8">
            <CreditCard size={64} className="text-blue-400" />
            <div>
              <h3 className="text-3xl font-bold tracking-tight mb-2">
                No Upgrade Plans Available
              </h3>
              <Text size="3" className="text-gray-600 max-w-md mx-auto">
                We're currently updating our pricing plans. Please check back
                later for new upgrade options.
              </Text>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {prices.map((price) => (
              <div key={price.id} className="border rounded-lg p-6 shadow-md">
                <h2 className="text-xl font-semibold mb-4">{price.name}</h2>
                <p className="text-2xl font-bold mb-4">
                  {currencyCodeToSymbol(price.currency)}
                  {price.amount / 100}/month
                </p>
                <ul className="mb-6">
                  {price.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <span className="mr-2">✓</span> {feature}
                    </li>
                  ))}
                </ul>
                <Button
                  className="w-full"
                  disabled={isCreatingCheckoutSession}
                  onClick={() => {
                    handleUpgrade(price.id);
                  }}
                >
                  Upgrade
                </Button>
              </div>
            ))}
          </div>
        )}
      </Flex>
    </Flex>
  );
};
