import { TypographyH4, TypographyP } from "@/components";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Skeleton } from "@/components/ui/skeleton";
import { SubscriptionDto } from "@/dtos";
import { useBrandContext } from "@/hooks";
import { useGetBrandByIdV2Query, useGetBrandSubscriptionQuery } from "@/redux";
import { Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

export const BillingContainer = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { data, isFetching } = useGetBrandByIdV2Query(
    { brandId: brand?.id || "" },
    { skip: !brand }
  );
  const { data: subscription } = useGetBrandSubscriptionQuery(
    { brandId: brand?.id || "" },
    { skip: !brand }
  );
  const navigate = useNavigate();

  const formatPrice = ({
    subscription,
  }: {
    subscription: SubscriptionDto;
  }): string => {
    const total = subscription.amount / 100;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: subscription.currency,
    }).format(total);
  };

  return (
    <Flex width="100%">
      {isFetching ? (
        <Skeleton className="h-6 w-full" />
      ) : (
        <Flex direction="column" width="100%" gap="4">
          <Flex>
            {subscription?.status === "active" ? (
              <TypographyP>
                You are currently subscribed to the{" "}
                <strong>{subscription?.plan?.name ?? "Basic"}</strong> plan.
              </TypographyP>
            ) : (
              <TypographyP>You are currently on the free tier.</TypographyP>
            )}
          </Flex>

          {subscription?.status === "active" && (
            <Flex gap="4">
              <Card className="w-[200px]">
                <CardHeader>
                  <CardTitle>Current monthly bill</CardTitle>
                </CardHeader>
                <CardContent>
                  {subscription ? (
                    <TypographyP>
                      {formatPrice({
                        subscription,
                      })}
                    </TypographyP>
                  ) : (
                    <TypographyP>No subscription data</TypographyP>
                  )}
                </CardContent>
              </Card>

              <Card className="w-[200px]">
                <CardHeader>
                  <CardTitle>Next payment due</CardTitle>
                </CardHeader>
                <CardContent>
                  {subscription ? (
                    <TypographyP>
                      {subscription?.nextBilledAt
                        ? new Date(
                            subscription.nextBilledAt
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : "No date available"}
                    </TypographyP>
                  ) : (
                    <TypographyP>No subscription data</TypographyP>
                  )}
                </CardContent>
              </Card>
            </Flex>
          )}

          <Card>
            <CardHeader>
              <Flex justify="between">
                <Flex direction="column">
                  <TypographyH4>Content Pieces</TypographyH4>
                  {subscription?.status !== "active" && (
                    <TypographyP className="text-sm">
                      Upgrade to unlock unlimited content pieces
                    </TypographyP>
                  )}
                </Flex>
                <TypographyH4>
                  <strong>{data?.quotas?.content?.used}</strong> of{" "}
                  <strong>
                    {data?.quotas?.content?.limit === -1
                      ? "∞"
                      : data?.quotas?.content?.limit}
                  </strong>{" "}
                  content pieces used
                </TypographyH4>
              </Flex>
            </CardHeader>
            <CardContent>
              <Progress
                value={
                  data?.quotas?.content?.limit === -1
                    ? 0
                    : ((data?.quotas?.content?.used ?? 0) * 100) /
                      (data?.quotas?.content?.limit ?? 0)
                }
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <Flex justify="between">
                <Flex direction="column">
                  <TypographyH4>Words</TypographyH4>
                  <TypographyP className="text-sm">
                    Included words quota resets in{" "}
                    {data?.quotas?.words?.endDate
                      ? `${Math.ceil(
                          (new Date(data?.quotas?.words?.endDate).getTime() -
                            new Date().getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} days`
                      : "N/A"}
                  </TypographyP>
                </Flex>
                <TypographyH4>
                  <strong>{data?.quotas?.words?.used}</strong> of{" "}
                  <strong>{data?.quotas?.words?.limit}</strong> words used
                </TypographyH4>
              </Flex>
            </CardHeader>
            <CardContent>
              <Progress
                value={
                  ((data?.quotas?.words?.used ?? 0) * 100) /
                  (data?.quotas?.words?.limit ?? 0)
                }
              />
            </CardContent>
          </Card>

          {subscription?.status !== "active" && (
            <Flex>
              <Button onClick={() => navigate("/upgrade")}>Upgrade</Button>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
