import { ContentDto, ContentType, LegacyContentDto } from "@/dtos";
import { baseApi, baseApiV2 } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({ addTagTypes: ["Content"] });

const contentApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getContentById: builder.query<
      { content: LegacyContentDto },
      { brandId: string; contentId: string }
    >({
      query: ({ brandId, contentId }) =>
        `/brands/${brandId}/content/${contentId}`,
      providesTags: ["Content"],
    }),
    createContent: builder.mutation<
      { content: ContentDto },
      { brandId: string; brief: string; type: string }
    >({
      query: ({ brandId, brief, type }) => ({
        url: `/brands/${brandId}/content`,
        method: "POST",
        body: { brief, type },
      }),
      invalidatesTags: ["Content"],
    }),
    createContentVersion: builder.mutation<
      void,
      { brandId: string; contentId: string; feedback?: string | null }
    >({
      query: ({ brandId, contentId, feedback }) => ({
        url: `/brands/${brandId}/content/${contentId}/versions`,
        method: "POST",
        body: { feedback },
      }),
      invalidatesTags: ["Content"],
    }),
    approveContent: builder.mutation<
      void,
      {
        brandId: string;
        contentId: string;
        publishDate: Date;
        linkedinOrganisationId?: string;
      }
    >({
      query: ({ brandId, contentId, publishDate, linkedinOrganisationId }) => ({
        url: `/brands/${brandId}/content/${contentId}/approve`,
        method: "POST",
        body: { publishDate, linkedinOrganisationId },
      }),
      invalidatesTags: ["Content"],
    }),
    cancelApproval: builder.mutation<
      void,
      { brandId: string; contentId: string }
    >({
      query: ({ brandId, contentId }) => ({
        url: `/brands/${brandId}/content/${contentId}/approve`,
        method: "DELETE",
      }),
      invalidatesTags: ["Content"],
    }),
  }),
});

const baseApiV2WithTags = baseApiV2.enhanceEndpoints({
  addTagTypes: ["ContentV2"],
});

const contentApiV2 = baseApiV2WithTags.injectEndpoints({
  endpoints: (builder) => ({
    getContent: builder.query<
      ContentDto[],
      { brandId: string; type?: ContentType; status?: string }
    >({
      query: ({ brandId, type, status }) => {
        const queryParams = [];
        if (type) {
          queryParams.push(`type=${type}`);
        }
        if (status) {
          queryParams.push(`status=${status}`);
        }
        return `/brands/${brandId}/content?${queryParams.join("&")}`;
      },
    }),
    archiveContent: builder.mutation<
      void,
      { brandId: string; contentId: string }
    >({
      query: ({ brandId, contentId }) => ({
        url: `/brands/${brandId}/content/${contentId}/archive`,
        method: "POST",
      }),
      invalidatesTags: ["ContentV2"],
    }),
    createContentVersion: builder.mutation<
      void,
      { brandId: string; contentId: string; feedback?: string }
    >({
      query: ({ brandId, contentId, feedback }) => ({
        url: `/brands/${brandId}/content/${contentId}/versions`,
        method: "POST",
        body: { feedback },
      }),
      invalidatesTags: ["ContentV2"],
    }),
  }),
});

export const {
  useGetContentByIdQuery,
  useApproveContentMutation,
  useCancelApprovalMutation,
  useCreateContentMutation,
} = contentApi;

export const {
  useGetContentQuery,
  useArchiveContentMutation,
  useCreateContentVersionMutation,
} = contentApiV2;

export const { invalidateTags: invalidateContentTags } = contentApi.util;
