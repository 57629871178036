import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const OtpContainer = (): JSX.Element => {
  const { handleError, handleLogin, supabase } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!supabase) return;

    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error) {
        navigate(`/auth/login?error=${error.message}`);
      }

      if (data.session) {
        handleLogin(data.session.access_token);
      }

      navigate("/");
    };

    getSession();
  }, [supabase, handleError, handleLogin, navigate]);

  return (
    <Flex
      direction="column"
      align="center"
      justify="between"
      width="100%"
      height="100%"
    >
      <Flex />

      <Flex
        direction="column"
        gap="6"
        align="center"
        justify="center"
        width={{ initial: "75%", sm: "50%" }}
      >
        <Skeleton className="h-[200px] w-full rounded-lg" />
      </Flex>

      <Flex />
    </Flex>
  );
};
