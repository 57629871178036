import { TypographyH1, TypographyP } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/hooks/use-auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex } from "@radix-ui/themes";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { AlertCircle, Wand2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

const SignupFormSchema = z.object({
  email: z.string().email(),
});

export const SignupContainer = (): JSX.Element => {
  const form = useForm<z.infer<typeof SignupFormSchema>>({
    resolver: zodResolver(SignupFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const { token, handleLogin, supabase } = useAuth();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [googleButtonWidth, setGoogleButtonWidth] = useState(400);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [magicLinkError, setMagicLinkError] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 640) {
        // 'sm' breakpoint in most Tailwind configs
        setGoogleButtonWidth(300);
      } else {
        setGoogleButtonWidth(400);
      }
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!token) return;
    navigate("/");
  }, [token, navigate]);

  const onEmailSubmit = async (
    data: z.infer<typeof SignupFormSchema>
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await supabase?.auth.signInWithOtp({
        email: data.email,
        options: { emailRedirectTo: `${window.location.origin}/auth/otp` },
      });
      setMagicLinkSent(true);
    } catch (error) {
      if (error instanceof Error) {
        setMagicLinkError(error.message);
      } else {
        setMagicLinkError("Something went wrong, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onGoogleLogin = async (credentialResponse: CredentialResponse) => {
    if (!supabase) {
      setMagicLinkError("Something went wrong, please try again.");
      return;
    }

    const { data, error } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: credentialResponse.credential ?? "",
    });

    if (error) {
      setMagicLinkError(error.message);
      return;
    }

    handleLogin(data.session.access_token);
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="between"
      width="100%"
      height="100%"
    >
      <Flex />

      <Flex
        direction="column"
        gap="6"
        align="center"
        justify="center"
        width={{ initial: "300px", sm: "400px" }}
      >
        <TypographyH1>Sign up</TypographyH1>

        <Flex
          direction="column"
          gap="2"
          align="center"
          justify="center"
          width="100%"
        >
          <GoogleLogin
            onSuccess={onGoogleLogin}
            onError={() => {
              setMagicLinkError("Google login failed");
            }}
            text="continue_with"
            logo_alignment="center"
            width={googleButtonWidth}
            useOneTap={true}
            use_fedcm_for_prompt={true}
            nonce=""
          />
        </Flex>

        <Flex align="center" justify="center" gap="6" width="100%">
          <Separator style={{ flex: 1 }} />
          <TypographyP className="pt-0">or</TypographyP>
          <Separator style={{ flex: 1 }} />
        </Flex>

        <Flex
          direction="column"
          gap="4"
          align="center"
          justify="center"
          width="100%"
        >
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onEmailSubmit)}
              className="w-full"
            >
              <Flex
                direction="column"
                gap="4"
                align="center"
                justify="center"
                width="100%"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem style={{ width: "100%" }}>
                      <FormControl>
                        <Input {...field} placeholder="john.doe@email.com" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  variant="default"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={isLoading}
                >
                  <Flex align="center" justify="center" gap="2">
                    <Wand2 className="h-4 w-4" />
                    Continue with magic link
                  </Flex>
                </Button>

                {magicLinkSent && (
                  <Flex
                    direction="column"
                    gap="3"
                    className="w-full p-4 rounded-lg bg-green-50 border border-green-100"
                  >
                    <TypographyP className="text-center font-medium text-green-700">
                      Magic link sent successfully! ✨
                    </TypographyP>
                    <TypographyP className="text-center text-sm text-green-600">
                      We've sent a secure login link to your email address.
                    </TypographyP>
                    <TypographyP className="text-center text-xs text-green-500 mt-1">
                      Didn't receive it? Check your spam folder or try again in
                      a few minutes.
                    </TypographyP>
                  </Flex>
                )}
                {magicLinkError && (
                  <Flex
                    direction="column"
                    gap="3"
                    className="w-full p-4 rounded-lg bg-red-50 border border-red-100"
                  >
                    <Flex direction="column" align="center" gap="2">
                      <AlertCircle className="h-6 w-6 text-red-600" />
                      <TypographyP className="text-center font-medium text-red-700">
                        Something went wrong
                      </TypographyP>
                    </Flex>
                    <TypographyP className="text-center text-sm text-red-600">
                      {magicLinkError}
                    </TypographyP>
                    <TypographyP className="text-center text-xs text-red-500 mt-1">
                      Please try again or contact support if the issue persists.
                    </TypographyP>
                  </Flex>
                )}
              </Flex>
            </form>
          </Form>
        </Flex>

        <Flex align="center" justify="center" gap="2">
          <TypographyP className="pt-0">Already have an account?</TypographyP>
          <Link to="/auth/login">Sign in.</Link>
        </Flex>
      </Flex>

      <Flex style={{ textAlign: "center", fontSize: "0.75rem" }}>
        <TypographyP>
          By signing up, you agree to our{" "}
          <Link to="https://disprse.com/terms" target="_blank">
            Terms of Service
          </Link>{" "}
          &{" "}
          <Link to="https://disprse.com/privacy" target="_blank">
            Privacy Policy
          </Link>
          .
        </TypographyP>
      </Flex>
    </Flex>
  );
};
