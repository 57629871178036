import { ContentForm, ScheduleForm } from "@/components/forms";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEffect, useState } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { useBrandContext, useNotificationsContext } from "@/hooks";
import { useNavigate } from "react-router-dom";

import { ContentCard, ContentErrors } from "@/components";
import { AxiosError } from "axios";
import {
  useArchiveContentMutation,
  useCreateContentVersionMutation,
  useGetBrandsQuery,
  useGetContentQuery,
} from "@/redux";
import { useAuth } from "@/hooks/use-auth";
import { Archive, Wand2, Lightbulb } from "lucide-react";

export const ContentIdeas = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { addNotification, addError } = useNotificationsContext();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [error, setError] = useState<Error | null>(null);
  const [addIdeaDialogOpen, setAddIdeaDialogOpen] = useState(false);
  const [generateIdeasDialogOpen, setGenerateIdeasDialogOpen] = useState(false);

  const { error: brandsLoadingError } = useGetBrandsQuery(undefined, {
    skip: !token,
  });

  const [archiveContent] = useArchiveContentMutation();
  const [createContentVersion] = useCreateContentVersionMutation();

  const {
    data: content,
    refetch: reloadContent,
    error: contentLoadingError,
  } = useGetContentQuery(
    {
      brandId: brand?.id || "",
      status: "idea",
    },
    {
      skip: !brand?.id,
    }
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadContent();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [reloadContent]);

  useEffect(() => {
    if (!error) return;

    addError({ message: error.message });
  }, [error, addError]);

  const onCreateContentSuccess = ({ contentId }: { contentId: string }) => {
    reloadContent();
    setAddIdeaDialogOpen(false);
    navigate(`/brands/${brand?.id}/content/${contentId}`);
  };

  const onGenerateIdeasSuccess = () => {
    setGenerateIdeasDialogOpen(false);

    addNotification({
      message:
        "Your ideas are being generated! We'll let you know when they are ready.",
    });
  };

  const handleArchiveContent = async ({ contentId }: { contentId: string }) => {
    await archiveContent({ brandId: brand?.id || "", contentId });
    reloadContent();
  };

  const handleCreateContentVersion = async ({
    contentId,
  }: {
    contentId: string;
  }) => {
    await createContentVersion({ brandId: brand?.id || "", contentId });
    reloadContent();
  };

  const renderButtons = () => (
    <Flex
      gap="2"
      mb="4"
      justify={content && content?.length > 0 ? "start" : "center"}
    >
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger>
            <Button
              className={`${
                brand === null ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => setGenerateIdeasDialogOpen(true)}
            >
              ✨ Generate Ideas
            </Button>
          </TooltipTrigger>
          {brand === null && (
            <TooltipContent>
              <p>You must select a brand to generate ideas</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger>
            <Button
              className={`${
                brand === null ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => setAddIdeaDialogOpen(true)}
            >
              Add an Idea
            </Button>
          </TooltipTrigger>
          {brand === null && (
            <TooltipContent>
              <p>You must select a brand to add an idea</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </Flex>
  );

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Ideas</h1>
        {content && content?.length > 0 && renderButtons()}
      </Flex>

      <ContentErrors
        error={
          (brandsLoadingError && "message" in brandsLoadingError
            ? brandsLoadingError.message
            : undefined) ||
          (contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : undefined) ||
          undefined
        }
      />

      <Flex height="80%" direction="column">
        {(!content || content?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-6 text-center p-8">
            <Lightbulb size={64} className="text-yellow-400" />
            <div>
              <h3 className="text-3xl font-bold tracking-tight mb-2">
                Your Idea Board is Empty
              </h3>
              <Text size="3" className="text-gray-600 max-w-md mx-auto">
                Start your creative journey by generating ideas or adding your
                own. Each idea is a potential masterpiece waiting to be
                explored!
              </Text>
            </div>
            <div className="space-y-4">
              {renderButtons()}
              <Text size="2" className="text-gray-500">
                Need inspiration? Try our AI-powered idea generator!
              </Text>
            </div>
          </div>
        )}

        {content && content?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%" pb="4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {content.map((item) => (
                  <ContentCard
                    key={item.id}
                    content={item}
                    onClick={() => {
                      navigate(`/brands/${brand?.id}/content/${item.id}`);
                    }}
                    actions={[
                      {
                        name: "Generate",
                        onClick: () => {
                          handleCreateContentVersion({ contentId: item.id });
                        },
                        icon: Wand2,
                        iconClassName: "text-purple-500",
                      },
                      {
                        name: "Archive",
                        onClick: () => {
                          handleArchiveContent({ contentId: item.id });
                        },
                        icon: Archive,
                        iconClassName: "text-red-500",
                      },
                    ]}
                  />
                ))}
              </div>
            </Flex>
          </Flex>
        )}

        <Flex>
          <Dialog
            open={generateIdeasDialogOpen}
            onOpenChange={setGenerateIdeasDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Generate Ideas</DialogTitle>
              </DialogHeader>
              <ScheduleForm
                brand={brand!}
                onSuccess={onGenerateIdeasSuccess}
                onError={(error) => {
                  if (error instanceof AxiosError) {
                    setError(new Error(error.response?.data.message));
                  } else {
                    setError(
                      new Error("Something went wrong. Please try again later.")
                    );
                  }
                  setGenerateIdeasDialogOpen(false);
                }}
              />
            </DialogContent>
          </Dialog>
        </Flex>

        <Flex>
          <Dialog open={addIdeaDialogOpen} onOpenChange={setAddIdeaDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add an Idea</DialogTitle>
              </DialogHeader>
              <ContentForm
                brand={brand!}
                onSuccess={(content) =>
                  onCreateContentSuccess({ contentId: content.id })
                }
              />
            </DialogContent>
          </Dialog>
        </Flex>
      </Flex>
    </>
  );
};
