import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { onboardingBrandFormSchema } from "./onboarding-brand.schema";

export interface OnboardingBrandComponentProps {
  form: UseFormReturn<z.infer<typeof onboardingBrandFormSchema>>;
  handleSubmit: (values: z.infer<typeof onboardingBrandFormSchema>) => void;
}

export const OnboardingBrandComponent = ({
  form,
  handleSubmit,
}: OnboardingBrandComponentProps): JSX.Element => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Brand Name</FormLabel>
              <FormControl>
                <Input placeholder="Marketer AI" {...field} />
              </FormControl>
              <FormDescription>This is the name of your brand.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="mission"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mission</FormLabel>
              <FormControl>
                <Input
                  placeholder="To make marketing easy for everyone"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the mission of your brand.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="homepage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Homepage</FormLabel>
              <FormControl>
                <Input placeholder="https://marketer.ai" {...field} />
              </FormControl>
              <FormDescription>
                This is the homepage of your brand, we'll use this to learn more
                about what you do so we can target the content to your audience.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};
