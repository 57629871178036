import { TypographyH3 } from "../typography";
import { CalendarMonthComponent } from "./calendar-month.component";
import { CalendarUpcomingComponent } from "./calendar-upcoming.component";
import { CalendarEvent } from "./types";

export interface CalendarComponentProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
}

export const CalendarComponent = ({
  events,
  onEventClick,
}: CalendarComponentProps): JSX.Element => {
  const upcoming = events.filter((event) => {
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
    return new Date(event.date) < oneWeekFromNow;
  });

  return (
    <div className="flex flex-row w-full h-full gap-8">
      <div className="w-2/3">
        <CalendarMonthComponent
          events={events?.sort((a, b) => a.date.getTime() - b.date.getTime())}
          onEventClick={onEventClick}
        />
      </div>
      <div className="w-1/3 flex flex-col gap-4 pt-2">
        <TypographyH3>Upcoming</TypographyH3>
        <CalendarUpcomingComponent
          events={upcoming?.sort((a, b) => a.date.getTime() - b.date.getTime())}
          onEventClick={onEventClick}
        />
      </div>
    </div>
  );
};
