import React from "react";
import { ContentDto } from "@/dtos";
import {
  Calendar,
  FileText,
  Tag,
  Image,
  MoreHorizontal,
  LucideIcon,
} from "lucide-react";
import { Card } from "../ui/card";
import { Box, Flex, Text, Badge } from "@radix-ui/themes";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

interface ContentCardProps {
  content: ContentDto;
  onClick: () => void;
  actions?: {
    name: string;
    icon: LucideIcon;
    iconClassName?: string;
    onClick: () => void;
  }[];
}

export const ContentCard: React.FC<ContentCardProps> = ({
  content,
  onClick,
  actions,
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "draft":
        return "yellow";
      case "published":
        return "green";
      case "researching":
      case "generating":
      case "generating_images":
        return "blue";
      case "failed":
      case "images_failed":
        return "red";
      default:
        return "gray";
    }
  };

  const getContentTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case "blog post":
        return <FileText className="text-blue-500" />;
      case "social media post":
        return <Tag className="text-purple-500" />;
      default:
        return <FileText className="text-gray-500" />;
    }
  };

  const handleCardClick = (e: React.MouseEvent) => {
    // Prevent onClick from firing when clicking the dropdown menu
    if (!(e.target as HTMLElement).closest(".dropdown-menu")) {
      onClick();
    }
  };

  return (
    <Card
      className="overflow-hidden h-full cursor-pointer"
      onClick={handleCardClick}
    >
      <Flex className="h-full">
        <Box className="w-1/3 h-full flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100">
          {content.versions[0]?.posts[0]?.thumbnail ? (
            <img
              src={content.versions[0].posts[0].thumbnail}
              alt="Thumbnail"
              className="w-full h-full object-cover"
            />
          ) : (
            <Image className="h-16 w-16 text-gray-400" />
          )}
        </Box>
        <Flex direction="column" justify="between" className="w-2/3 p-5">
          <Box>
            <Text size="5" weight="bold" className="line-clamp-2 mb-2">
              {content.versions[0]?.posts[0]?.title ?? content.brief}
            </Text>
            <Badge
              variant="soft"
              color={getStatusColor(content.status)}
              className="mb-2"
            >
              {content.status.charAt(0).toUpperCase() +
                content.status.slice(1).replace("_", " ")}
            </Badge>
          </Box>
          <Flex direction="column" gap="3">
            <Flex align="center" gap="2">
              {getContentTypeIcon(content.type)}
              <Text size="2" color="gray">
                {content.type}
              </Text>
            </Flex>
            <Flex align="center" gap="2">
              <Calendar size={16} className="text-orange-500" />
              <Text size="2" color="gray">
                {new Date(content.createdAt).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </Text>
            </Flex>
          </Flex>
          <Flex justify="between" align="center" mt="4">
            <Badge variant="outline" size="1">
              {content.versions.length} version
              {content.versions.length !== 1 ? "s" : ""}
            </Badge>
            {actions && actions.length > 0 && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="hover:bg-gray-100 dropdown-menu"
                  >
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {actions.map((action) => (
                    <DropdownMenuItem
                      key={action.name}
                      onClick={(e) => {
                        e.stopPropagation();
                        action.onClick();
                      }}
                    >
                      <action.icon
                        className={`mr-2 h-4 w-4 ${action.iconClassName}`}
                      />
                      {action.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
