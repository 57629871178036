import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import { LegacyContentDto } from "@/dtos";
import { useEffect } from "react";
import { useCreateContentVersionMutation } from "@/redux";

const contentVersionFormSchema = z.object({
  brandId: z.string().uuid(),
  contentId: z.string().uuid(),
  feedback: z.string().min(1).max(500).optional().nullable(),
});

export interface ContentVersionFormProps {
  brand: { name: string; id: string };
  content: LegacyContentDto;
  onSuccess: (content: LegacyContentDto) => void;
  onError: (error: Error) => void;
  firstDraft?: boolean;
  displayLabel?: boolean;
  buttonText?: string;
  buttonFullWidth?: boolean;
  disabled?: boolean;
}

export const ContentVersionForm = ({
  brand,
  content,
  onSuccess,
  onError,
  firstDraft = false,
  displayLabel = true,
  buttonText = "Submit",
  buttonFullWidth = false,
  disabled = false,
}: ContentVersionFormProps): JSX.Element => {
  const [createContentVersion, { isLoading, isError, isSuccess, error }] =
    useCreateContentVersionMutation();

  const form = useForm<z.infer<typeof contentVersionFormSchema>>({
    resolver: zodResolver(contentVersionFormSchema),
    defaultValues: {
      brandId: "",
      contentId: "",
      feedback: null,
    },
  });

  useEffect(() => {
    if (brand) {
      form.setValue("brandId", brand.id);
    }
  }, [brand, form]);

  useEffect(() => {
    if (content) {
      form.setValue("contentId", content.id);
    }
  }, [content, form]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess(content);
    }
  }, [isSuccess, onSuccess, content]);

  useEffect(() => {
    if (isError) {
      onError(
        error instanceof Error
          ? error
          : new Error("Something went wrong, please try again.")
      );
    }
  }, [isError, onError, error]);

  const onSubmit = async (values: z.infer<typeof contentVersionFormSchema>) => {
    createContentVersion({
      brandId: values.brandId,
      contentId: values.contentId,
      feedback: values.feedback || undefined,
    });
  };

  if (firstDraft) {
    return (
      <Button onClick={form.handleSubmit(onSubmit)}>
        ✨ Request first draft
      </Button>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3 w-full">
        <FormField
          control={form.control}
          name="feedback"
          disabled={disabled || isLoading}
          render={({ field }) => (
            <FormItem>
              {displayLabel && <FormLabel>Feedback</FormLabel>}
              <FormControl>
                <Input
                  placeholder="E.g expand on the section about SEO and add some examples."
                  {...field}
                  value={field.value || ""}
                />
              </FormControl>
              {displayLabel && (
                <FormDescription>
                  Provide additional feedback to help Marketer AI generate a new
                  version.
                </FormDescription>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className={buttonFullWidth ? "w-full" : ""}
          disabled={disabled || isLoading}
        >
          {buttonText}
        </Button>
      </form>
    </Form>
  );
};
