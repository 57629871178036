import { WriterCard } from "@/components/writer-card";
import { WriterDto } from "@/dtos";

export interface OnboardingContentComponentProps {
  writer: WriterDto;
}

export const OnboardingContentComponent = ({
  writer,
}: OnboardingContentComponentProps): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-[250px]">
      <WriterCard
        writer={writer}
        onPause={() => {}}
        onResume={() => {}}
        onDelete={() => {}}
      />
    </div>
  );
};
