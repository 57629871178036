import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Toolbar from "./toolbar";
import BulletList from "@tiptap/extension-bullet-list";
import Link from "@tiptap/extension-link";
import { EditorMenu } from "./menu";
import Paragraph from "@tiptap/extension-paragraph";
import { useEffect } from "react";
import { Card } from "../ui/card";

type TipTapProps = {
  connected: boolean;
  html: string;
  saving: boolean;
  lastSaved: string | null;
  onUpdate: ({ html }: { html: string }) => void;
  key: string;
  bubbleMenu?: boolean;
  editable?: boolean;
  isDraft?: boolean;
  enableImages?: boolean;
};

const Tiptap = ({
  connected,
  html,
  onUpdate,
  saving,
  lastSaved,
  key,
  bubbleMenu = true,
  editable = true,
  enableImages = true,
}: TipTapProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }).extend({
        renderHTML({ node, HTMLAttributes }) {
          const level = node.attrs.level as 1 | 2 | 3 | 4 | 5 | 6;
          const classes = {
            1: "text-4xl font-bold tracking-tight py-6 text-gray-900",
            2: "text-3xl font-semibold tracking-tight py-5 text-gray-900",
            3: "text-2xl font-medium tracking-tight py-4 text-gray-800",
            4: "text-xl font-medium tracking-tight py-3 text-gray-800",
            5: "text-lg font-medium tracking-tight py-2 text-gray-700",
            6: "text-base font-medium tracking-tight py-2 text-gray-700",
          };
          HTMLAttributes.class = classes[level];
          return [`h${level}`, HTMLAttributes, 0];
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: "text-gray-600 leading-relaxed py-2 text-lg",
        },
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc pl-8 py-3 text-gray-600",
        },
      }),
      Image.configure({
        inline: true,
        HTMLAttributes: {
          class:
            "w-[60%] rounded-xl shadow-lg my-6 mx-auto hover:shadow-xl transition-shadow duration-200",
        },
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
        HTMLAttributes: {
          class:
            "text-blue-600 hover:text-blue-800 transition-colors duration-200 underline decoration-blue-300 hover:decoration-blue-500",
        },
      }),
    ],
    content: html,
    editorProps: {
      attributes: {
        class: `p-6 prose prose-lg max-w-none prose-headings:font-display prose-p:font-normal`,
      },
    },
    editable,
    onUpdate() {
      onUpdate({ html: editor?.getHTML() || "" });
    },
  });

  useEffect(() => {
    if (editor && html !== editor.getHTML()) {
      const { from, to } = editor.state.selection;
      editor.commands.setContent(html, false);
      editor.commands.setTextSelection({ from, to });
    }
  }, [html, editor]);

  useEffect(() => {
    if (!editable) {
      editor?.setOptions({ editable: false });
    }
  }, [editable, editor]);

  return (
    <Card className="flex flex-col" key={key}>
      {editable && (
        <Toolbar
          editor={editor}
          connected={connected}
          saving={saving}
          lastSaved={lastSaved}
          enableImages={enableImages}
        />
      )}
      <EditorContent
        editor={editor}
        disabled={!editable}
        className="min-h-[300px]"
      />
      {editor && bubbleMenu && (
        <BubbleMenu
          editor={editor}
          className="bg-white border border-gray-200 rounded-lg shadow-lg w-[368px] backdrop-blur-sm bg-white/95"
          tippyOptions={{ duration: 200 }}
        >
          <EditorMenu
            editor={editor}
            connected={connected}
            saving={saving}
            lastSaved={lastSaved}
            compact={true}
            enableImages={enableImages}
          />
        </BubbleMenu>
      )}
    </Card>
  );
};

export default Tiptap;
