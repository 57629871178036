import {
  OnboardingCreateBrandDto,
  OnboardingCreateWriterDto,
  OnboardingDto,
} from "@/dtos";
import { baseApiV2 } from "./base-api.slice";

const baseApiV2WithTags = baseApiV2.enhanceEndpoints({
  addTagTypes: ["Onboarding"],
});

const onboardingApiV2 = baseApiV2WithTags.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingStatus: builder.query<OnboardingDto, void>({
      query: () => "/onboarding/status",
      providesTags: ["Onboarding"],
    }),
    createOnboardingBrand: builder.mutation<void, OnboardingCreateBrandDto>({
      query: (body) => ({
        url: "/onboarding/brand",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Onboarding"],
    }),
    createOnboardingWriter: builder.mutation<void, OnboardingCreateWriterDto>({
      query: (body) => ({
        url: "/onboarding/writer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Onboarding"],
    }),
    createOnboardingContent: builder.mutation<void, void>({
      query: () => ({
        url: "/onboarding/content",
        method: "POST",
      }),
      invalidatesTags: ["Onboarding"],
    }),
  }),
});

export const {
  useGetOnboardingStatusQuery,
  useCreateOnboardingBrandMutation,
  useCreateOnboardingWriterMutation,
  useCreateOnboardingContentMutation,
} = onboardingApiV2;

export const { invalidateTags: invalidateOnboardingTags } =
  onboardingApiV2.util;
