import React from "react";
import { WriterDto } from "@/dtos/writers";
import {
  Calendar,
  FileText,
  Pause,
  Play,
  Trash,
  MoreHorizontal,
  User,
  Zap,
  Feather,
} from "lucide-react";
import { Card } from "../ui/card";
import { Text, Avatar } from "@radix-ui/themes";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { createAvatar } from "@dicebear/core";
import { bottts } from "@dicebear/collection";
import { ContentType } from "@/dtos";
import { Badge } from "../ui/badge";

interface WriterCardProps {
  writer: WriterDto;
  onPause: (id: string) => void;
  onResume: (id: string) => void;
  onDelete: (id: string) => void;
}

export const WriterCard: React.FC<WriterCardProps> = ({
  writer,
  onPause,
  onResume,
  onDelete,
}) => {
  const getContentTypeIcon = (type: ContentType) => {
    switch (type) {
      case ContentType.BLOG_POST:
        return <Feather className="text-blue-500" />;
      case ContentType.X_POST:
        return <Zap className="text-purple-500" />;
      case ContentType.LINKEDIN_POST:
        return <FileText className="text-cyan-500" />;
      default:
        return <FileText className="text-gray-500" />;
    }
  };

  const formatDaysOfWeek = (days: string[]) => {
    if (days.length === 7) return "Every day";
    if (days.length === 0) return "No days selected";
    return days.join(", ");
  };

  const formatTimeOfDay = (hours: number[], minutes: number[]): string[] => {
    return hours.flatMap((hour) =>
      minutes.map((minute) => `${hour}:${minute.toString().padStart(2, "0")}`)
    );
  };

  const avatar = createAvatar(bottts, {
    seed: writer.name,
    size: 64,
  });

  return (
    <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer overflow-hidden h-full border-radius-md bg-gradient-to-br from-white to-gray-50">
      <div className="flex h-full">
        <div className="w-1/3 h-full flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100">
          <Avatar
            size="7"
            fallback={<User className="h-full w-full text-gray-400" />}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              avatar.toString()
            )}`}
            className="border-4 border-white shadow-md"
          />
        </div>
        <div className="flex flex-col justify-between w-2/3 p-5">
          <div>
            <Text size="6" weight="bold" className="line-clamp-1 mb-2">
              {writer.name}
            </Text>
            <Badge variant="outline" className="mb-2">
              {writer.tone}
            </Badge>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              {getContentTypeIcon(writer.contentType)}
              <Text size="2" color="gray">
                {writer.contentType.replace("_", " ")}
              </Text>
            </div>
            <div className="flex items-center gap-2">
              <Calendar size={16} className="text-orange-500" />
              <Text size="2" color="gray">
                {formatDaysOfWeek(writer.daysOfWeek)} at{" "}
                {formatTimeOfDay(writer.hoursOfDay, writer.minutesOfHour).join(
                  ", "
                )}
              </Text>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <Badge variant={writer.isActive ? "default" : "outline"}>
              {writer.isActive ? "Active" : "Inactive"}
            </Badge>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm" className="hover:bg-gray-100">
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() =>
                    writer.isActive ? onPause(writer.id) : onResume(writer.id)
                  }
                >
                  {writer.isActive ? (
                    <>
                      <Pause className="mr-2 h-4 w-4 text-yellow-500" />
                      Pause
                    </>
                  ) : (
                    <>
                      <Play className="mr-2 h-4 w-4 text-green-500" />
                      Resume
                    </>
                  )}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onDelete(writer.id)}>
                  <Trash className="mr-2 h-4 w-4 text-red-500" />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </Card>
  );
};
