import { DomainCard } from "@/components";
import { useBrandContext, useNotificationsContext } from "@/hooks";
import { useGetBrandByIdQuery, useGetDomainsQuery } from "@/redux";
import { Flex } from "@radix-ui/themes";
import { useEffect } from "react";

export const DomainsListContainer = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { addError } = useNotificationsContext();
  const { data: brandData } = useGetBrandByIdQuery(
    { id: brand?.id || "" },
    { skip: !brand || !brand.id }
  );
  const { data, isLoading, isError, error } = useGetDomainsQuery(
    { brandId: brand?.id || "" },
    { skip: !brand || !brand.id }
  );

  useEffect(() => {
    if (isError) {
      if (error instanceof Error) {
        addError({ message: error.message });
      } else {
        addError({ message: "Error fetching domains" });
      }
    }
  }, [isError, addError, error]);

  return (
    <Flex direction="column" gap="4">
      {!isLoading &&
        data?.domains.map(({ id, domain, isDnsConfigured, verifications }) => (
          <DomainCard
            key={id}
            id={id}
            domain={domain}
            isVerified={isDnsConfigured}
            verifications={verifications}
          />
        ))}
      {!isLoading && (
        <DomainCard
          id={brandData?.brand.id || ""}
          domain={`disprse.com/sites/${brandData?.brand.slug}`}
          isVerified={true}
          verifications={[]}
        />
      )}
    </Flex>
  );
};
