import { z } from "zod";
import { WriterTone } from "@/dtos/writers";
import { ContentType } from "@/dtos";

export const onboardingWriterFormSchema = z.object({
  daysOfWeek: z.array(z.string()).min(1, "Select at least one day"),
  hoursOfDay: z.array(z.number()).min(1, "Select at least one hour"),
  minutesOfHour: z.array(z.number()).min(1, "Select at least one minute"),
  contentType: z.nativeEnum(ContentType),
  tone: z.nativeEnum(WriterTone),
});
