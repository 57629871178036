import { createContext, useEffect, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import {
  logout as reduxLogout,
  RootState,
  AppDispatch,
  setAuthError,
  selectAuthToken,
  selectAuthError,
  setAuthToken,
  useGetWhoamiQuery,
} from "@/redux";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "sonner";
import { createClient, SupabaseClient } from "@supabase/supabase-js";

export interface AuthContextType {
  supabase: SupabaseClient | null;
  token: string | null;
  user: { email: string; id: string } | undefined;
  error: string | null;
  handleLogin: (token: string) => void;
  handleError: (error: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>({
  supabase: null,
  token: null,
  user: undefined,
  error: null,
  handleLogin: () => {},
  handleError: () => {},
  logout: () => {},
});

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => selectAuthToken(state));
  const error = useSelector((state: RootState) => selectAuthError(state));
  const [supabase, setSupabase] = useState<SupabaseClient | null>(null);
  const { data: user } = useGetWhoamiQuery(undefined, {
    skip: !token,
  });

  useEffect(() => {
    setSupabase(
      createClient(
        import.meta.env.VITE_SUPABASE_URL,
        import.meta.env.VITE_SUPABASE_ANON_KEY
      )
    );
  }, []);

  const handleLogin = (token: string) => {
    dispatch(setAuthToken(token));
  };

  const handleError = (error: string) => {
    dispatch(setAuthError(error));
  };

  useEffect(() => {
    if (!error) return;
    toast.error(error, {
      duration: 5000,
    });
  }, [error]);

  const logout = () => {
    dispatch(reduxLogout());
    googleLogout();
    supabase?.auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{ supabase, token, user, error, handleLogin, handleError, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
