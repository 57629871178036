import { Flex } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";

export const AuthLayout = (): JSX.Element => {
  return (
    <Flex justify="center" align="center" className="h-[100vh]">
      <Flex
        width={{ initial: "100%", sm: "70%" }}
        height="100%"
        p={{ initial: "0px", sm: "12px" }}
        pl="0px"
        justify={"center"}
      >
        <Flex
          width="100%"
          height="100%"
          justify="center"
          align="center"
          direction="column"
          gap="24px"
          p={{ initial: "6px", sm: "24px" }}
        >
          <Flex justify="center" align="center" flexGrow="1" width="100%">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
