import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  useCreateBrandMutation,
  useCreateOnboardingContentMutation,
  useCreateWriterMutation,
  useGetBrandsV2Query,
  useGetContentQuery,
  useGetOnboardingStatusQuery,
  useGetWritersQuery,
} from "@/redux";
import { Button } from "@/components/ui/button";
import {
  OnboardingBrandComponent,
  onboardingBrandFormSchema,
  OnboardingContentComponent,
  OnboardingWriterComponent,
  onboardingWriterFormSchema,
} from "@/components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { ContentType, WriterTone } from "@/dtos";
import { useBrandContext } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/use-auth";
import { Globe, Lightbulb, Zap } from "lucide-react";

export const OnboardingContainer = (): JSX.Element => {
  const { token, user } = useAuth();
  const { data: onboardingResponse } = useGetOnboardingStatusQuery(undefined, {
    skip: !token || !user,
  });
  const { data: brandsResponse, refetch: refetchBrands } = useGetBrandsV2Query(
    undefined,
    { skip: !token || !user }
  );
  const [createBrand, { isLoading: isCreatingBrand }] =
    useCreateBrandMutation();
  const { data: writersResponse, refetch: refetchWriters } = useGetWritersQuery(
    { brandId: brandsResponse?.[0]?.id || "" },
    { skip: !brandsResponse?.[0]?.id }
  );
  const [createWriter, { isLoading: isCreatingWriter }] =
    useCreateWriterMutation();
  const { refetch: refetchContent } = useGetContentQuery(
    { brandId: brandsResponse?.[0]?.id || "" },
    { skip: !brandsResponse?.[0]?.id }
  );
  const [createContent, { isLoading: isCreatingContent }] =
    useCreateOnboardingContentMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const { setBrand } = useBrandContext();

  const brandForm = useForm<z.infer<typeof onboardingBrandFormSchema>>({
    resolver: zodResolver(onboardingBrandFormSchema),
    defaultValues: {
      name: "",
      mission: "",
      homepage: "",
    },
  });

  const writerForm = useForm<z.infer<typeof onboardingWriterFormSchema>>({
    resolver: zodResolver(onboardingWriterFormSchema),
    defaultValues: {
      daysOfWeek: ["Monday"],
      hoursOfDay: [9],
      minutesOfHour: [0],
      contentType: ContentType.BLOG_POST,
      tone: WriterTone.CASUAL,
    },
  });

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [isAwaitingContent, setIsAwaitingContent] = useState(false);

  useEffect(() => {
    if (onboardingResponse?.status === "NOT_STARTED") {
      setOpen(true);
    }
  }, [token, onboardingResponse, location.pathname]);

  useEffect(() => {
    if (brandsResponse?.length && brandsResponse.length > 0) {
      setBrand(brandsResponse[0]);
    }
  }, [brandsResponse, setBrand]);

  const handleCreateBrand = async (): Promise<void> => {
    if (brandForm.formState.isValid) {
      await createBrand(brandForm.getValues());
      refetchBrands();
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleCreateWriter = async (): Promise<void> => {
    if (writerForm.formState.isValid) {
      await createWriter({
        ...writerForm.getValues(),
        brandId: brandsResponse?.[0]?.id || "",
      });
      refetchWriters();
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleCreateContent = async (): Promise<void> => {
    await createContent();
    setIsAwaitingContent(true);

    let counter = 0;
    const interval = setInterval(async () => {
      const result = await refetchContent();
      if (result.data?.length && result.data.length > 0) {
        clearInterval(interval);
        setIsAwaitingContent(false);
        setOpen(false);
        navigate("/content/drafts");
      } else if (counter >= 12) {
        clearInterval(interval);
        setIsAwaitingContent(false);
        // Handle timeout case (e.g., show an error message)
      }
      counter++;
    }, 5000);
  };

  const handleNext = async (): Promise<void> => {
    switch (step) {
      case 1:
        await handleCreateBrand();
        break;
      case 2:
        await handleCreateWriter();
        break;
      case 3:
        await handleCreateContent();
        break;
      default:
        setStep((prevStep) => prevStep + 1);
        break;
    }
  };

  const renderStep = () => {
    const steps = [
      {
        title: "Welcome to Disprse",
        description:
          "We're excited to have you on board! Let's get you setup in just a few easy steps.",
        component: (
          <div className="relative w-full h-64 mb-8 overflow-hidden rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gradient-to-br from-blue-200 to-purple-200">
            <div className="absolute inset-0 bg-white opacity-40"></div>
            <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-800">
              <p className="text-xl mb-6 max-w-md text-center font-bold">
                Revolutionizing marketing with AI-powered agents
              </p>
              <div className="flex space-x-6">
                <div className="flex flex-col items-center">
                  <Lightbulb className="w-12 h-12 mb-2 text-blue-500" />
                  <span className="text-sm font-semibold">Smart</span>
                </div>
                <div className="flex flex-col items-center">
                  <Zap className="w-12 h-12 mb-2 text-purple-500" />
                  <span className="text-sm font-semibold">Fast</span>
                </div>
                <div className="flex flex-col items-center">
                  <Globe className="w-12 h-12 mb-2 text-cyan-500" />
                  <span className="text-sm font-semibold">On-Brand</span>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Create Your Brand",
        description:
          "Setting up your brand helps us to understand your audience and create content that resonates with them.",
        component: (
          <OnboardingBrandComponent
            form={brandForm}
            handleSubmit={handleNext}
          />
        ),
      },
      {
        title: "Create a Writer",
        description:
          "Writers are autonomous AI agents that produce content for your brand.",
        component: (
          <OnboardingWriterComponent
            form={writerForm}
            handleSubmit={handleNext}
          />
        ),
      },
      {
        title: !isAwaitingContent
          ? "Create Your First Content"
          : "Your Writer is at Work",
        description: !isAwaitingContent
          ? "We've setup a writer for your brand. Let's ask the writer to create some content now to see how well they understand your brand."
          : "",
        component:
          writersResponse && writersResponse.length > 0 ? (
            isAwaitingContent ? (
              <div className="flex flex-col justify-center items-center h-50 py-8">
                <div className="animate-bounce text-7xl mb-4">
                  <span role="img" aria-label="AI Writer">
                    ✨🤖✨
                  </span>
                </div>
                <div className="text-center text-lg font-semibold text-primary">
                  {writersResponse[0].name} is crafting your content
                </div>
                <div className="text-center text-sm text-gray-600 mt-2">
                  Harnessing AI to create engaging content tailored to your
                  brand...
                </div>
              </div>
            ) : (
              <OnboardingContentComponent writer={writersResponse[0]} />
            )
          ) : null,
      },
    ];

    return (
      <div>
        <DialogTitle className="text-2xl font-bold mb-4">
          {steps[step].title}
        </DialogTitle>
        <DialogDescription className="mb-4">
          {steps[step].description}
        </DialogDescription>
        {steps[step].component}
      </div>
    );
  };

  const renderProgressDots = () => {
    return (
      <div className="flex justify-center space-x-2">
        {[0, 1, 2, 3].map((dotStep) => (
          <div
            key={dotStep}
            className={`w-2 h-2 rounded-full ${
              step === dotStep ? "bg-primary" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    );
  };

  const isCurrentFormValid = (): boolean => {
    switch (step) {
      case 0:
        return true;
      case 1:
        return brandForm.formState.isValid;
      case 2:
        return writerForm.formState.isValid;
      case 3:
        return true;
      default:
        return false;
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[800px]">
        <DialogHeader>{renderStep()}</DialogHeader>
        <DialogFooter className="flex flex-col space-y-4">
          <div className="flex justify-between w-full">
            <Button onClick={() => setOpen(false)} variant="outline">
              Skip Intro
            </Button>
            <div className="flex items-center gap-4">
              {renderProgressDots()}
              <Button
                onClick={handleNext}
                variant="default"
                disabled={
                  isCreatingBrand ||
                  isCreatingWriter ||
                  isCreatingContent ||
                  isAwaitingContent ||
                  !isCurrentFormValid()
                }
              >
                Continue
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
