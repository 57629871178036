import { ContentVersionForm } from "@/components/forms";
import { TypographyH3 } from "@/components/typography";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { LegacyContentDto } from "@/dtos";
import { Flex } from "@radix-ui/themes";
import { RocketIcon } from "lucide-react";

export interface ContentIdeaComponentProps {
  brandId: string;
  content: LegacyContentDto;
  onNewVersionSuccess: () => void;
  onNewVersionError: (error: Error) => void;
}

export const ContentIdeaComponent = ({
  brandId,
  content,
  onNewVersionSuccess,
  onNewVersionError,
}: ContentIdeaComponentProps): JSX.Element => {
  return (
    <Flex pt="2" pb="7" direction="column" gap="4">
      <TypographyH3>{content.brief}</TypographyH3>
      <Alert>
        <Flex justify="start" align="center" gap="4">
          <RocketIcon size={25} />
          <Flex direction="column">
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              This content hasn't been generated yet. Click the button below to
              ask Marketer AI to generate a first draft.
            </AlertDescription>
          </Flex>
        </Flex>
      </Alert>

      <Flex width="30%">
        <ContentVersionForm
          firstDraft={true}
          brand={{ id: brandId!, name: "" }}
          content={content}
          onSuccess={onNewVersionSuccess}
          onError={onNewVersionError}
        />
      </Flex>
    </Flex>
  );
};
