import { WriterDto } from "@/dtos";
import { baseApiV2 } from "./base-api.slice";

const baseApiV2WithTags = baseApiV2.enhanceEndpoints({
  addTagTypes: ["Writers"],
});

const writersApiV2 = baseApiV2WithTags.injectEndpoints({
  endpoints: (builder) => ({
    createWriter: builder.mutation<
      void,
      { brandId: string } & Omit<
        WriterDto,
        "id" | "createdAt" | "updatedAt" | "isActive" | "name" | "avatar"
      >
    >({
      query: ({ brandId, ...body }) => ({
        url: `/brands/${brandId}/writers`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Writers"],
    }),
    getWriters: builder.query<WriterDto[], { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}/writers`,
      providesTags: ["Writers"],
    }),
  }),
});

export const { useCreateWriterMutation, useGetWritersQuery } = writersApiV2;

export const { invalidateTags: invalidateWritersTags } = writersApiV2.util;
