import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { WriterTone } from "@/dtos/writers";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { ContentType } from "@/dtos";

const writerFormSchema = z.object({
  daysOfWeek: z.array(z.string()).min(1, "Select at least one day"),
  hoursOfDay: z.array(z.number()).min(1, "Select at least one hour"),
  minutesOfHour: z.array(z.number()).min(1, "Select at least one minute"),
  contentType: z.nativeEnum(ContentType),
  tone: z.nativeEnum(WriterTone),
});

export interface WriterFormV2Props {
  onSubmit: (values: z.infer<typeof writerFormSchema>) => void;
}

export const WriterFormV2 = ({ onSubmit }: WriterFormV2Props): JSX.Element => {
  const form = useForm<z.infer<typeof writerFormSchema>>({
    resolver: zodResolver(writerFormSchema),
    defaultValues: {
      hoursOfDay: [9],
      minutesOfHour: [0],
      daysOfWeek: ["Monday"],
      contentType: ContentType.BLOG_POST,
      tone: WriterTone.CASUAL,
    },
  });

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="daysOfWeek"
          render={() => (
            <FormItem>
              <FormLabel>Active Days</FormLabel>
              <div className="flex flex-wrap gap-4">
                {daysOfWeek.map((day) => (
                  <FormField
                    key={day}
                    control={form.control}
                    name="daysOfWeek"
                    render={({ field }) => {
                      return (
                        <FormItem
                          key={day}
                          className="flex flex-row items-start space-x-3 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(day)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...field.value, day])
                                  : field.onChange(
                                      field.value?.filter(
                                        (value) => value !== day
                                      )
                                    );
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">{day}</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                ))}
              </div>
              <FormDescription>
                What days of the week do you want the writer to create content?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="hoursOfDay"
          render={({ field: hoursField }) => (
            <FormItem>
              <FormLabel>Time of Day</FormLabel>
              <div className="flex gap-2">
                <FormControl>
                  <Select
                    onValueChange={(value) =>
                      hoursField.onChange([parseInt(value)])
                    }
                    value={hoursField.value[0]?.toString()}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Hour" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 24 }, (_, i) => (
                        <SelectItem key={i} value={i.toString()}>
                          {i.toString().padStart(2, "0")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormField
                  control={form.control}
                  name="minutesOfHour"
                  render={({ field: minutesField }) => (
                    <FormControl>
                      <Select
                        onValueChange={(value) =>
                          minutesField.onChange([parseInt(value)])
                        }
                        value={minutesField.value[0]?.toString()}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Minute" />
                        </SelectTrigger>
                        <SelectContent>
                          {Array.from({ length: 60 }, (_, i) => (
                            <SelectItem key={i} value={i.toString()}>
                              {i.toString().padStart(2, "0")}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                  )}
                />
                <div className="flex items-center text-sm text-gray-500">
                  UTC
                </div>
              </div>
              <FormDescription>
                When should the writer have the content ready for review?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="contentType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Content Type</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a content type" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(ContentType).map(([key, value]) => (
                      <SelectItem key={key} value={value}>
                        {value
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription>
                What type of content do you want the writer to create?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tone</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a tone" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(WriterTone).map(([key, value]) => (
                      <SelectItem key={key} value={value}>
                        {value
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription>
                What tone do you want the writer to use?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full">
          Create Writer
        </Button>
      </form>
    </Form>
  );
};
