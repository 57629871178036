import { useEffect } from "react";
import { Flex } from "@radix-ui/themes";
import { useBrandContext } from "@/hooks";
import { useNavigate } from "react-router-dom";

import { ContentCard, ContentErrors } from "@/components";
import { useGetBrandsQuery, useGetContentQuery } from "@/redux";

export const ContentPublished = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { error: brandsLoadingError } = useGetBrandsQuery();
  const navigate = useNavigate();

  const {
    data: content,
    refetch: reloadContent,
    error: contentLoadingError,
  } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand?.id,
    }
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadContent();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [reloadContent]);

  const published = content?.filter((c) => c.status === "final");

  return (
    <>
      <Flex justify="between" pb="6">
        <h1 className="text-lg font-semibold md:text-2xl">Published</h1>
      </Flex>

      <ContentErrors
        error={
          (brandsLoadingError && "message" in brandsLoadingError
            ? brandsLoadingError.message
            : undefined) ||
          (contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : undefined) ||
          undefined
        }
      />

      <Flex height="80%">
        {(!published || published?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no published content yet
            </h3>
          </div>
        )}

        {published && published?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%" pb="4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {published.map((item) => (
                  <ContentCard
                    key={item.id}
                    content={item}
                    onClick={() => {
                      navigate(`/brands/${brand?.id}/content/${item.id}`);
                    }}
                  />
                ))}
              </div>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
