import * as React from "react";
import { GalleryVerticalEnd, Settings2, Users } from "lucide-react";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar";
import { NavMain } from "./nav-main";
import { NavUser } from "./nav-user";
import { useAuth } from "@/hooks/use-auth";
import { useBrandContext } from "@/hooks";
import { useGetBrandsV2Query } from "@/redux";
import { useState } from "react";
import { BrandSwitcher } from "./brand-switcher";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { BrandForm } from "../forms";
import { BrandDTO } from "@/dtos";
import { UpgradeCard } from "@/components/upgrade-card";

const data = {
  navMain: [
    {
      title: "Content",
      url: "#",
      icon: GalleryVerticalEnd,
      isActive: true,
      items: [
        {
          title: "Calendar",
          url: "/content/schedule",
        },
        {
          title: "Writers",
          url: "/automation/writers",
        },
        {
          title: "Ideas",
          url: "/content/ideas",
        },
        {
          title: "Drafts",
          url: "/content/drafts",
        },
        {
          title: "Published",
          url: "/content/published",
        },
      ],
    },
    {
      title: "Settings",
      url: "#",
      icon: Settings2,
      items: [
        {
          title: "Billing",
          url: "/settings/billing",
        },
        {
          title: "Domains",
          url: "/settings/domains",
        },
        {
          title: "Social Media",
          url: "/integrations",
        },
      ],
    },
    {
      title: "Team",
      url: "#",
      icon: Users,
      items: [
        {
          title: "Members",
          url: "/team-members",
        },
      ],
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { token, user } = useAuth();
  const { setBrand } = useBrandContext();
  const { data: brandsResponse } = useGetBrandsV2Query(undefined, {
    skip: !token || !user,
  });
  const [brandFormOpen, setBrandFormOpen] = useState(false);
  const [brandFormError, setBrandFormError] = useState<Error | null>(null);

  const handleBrandFormSuccess = async (brand: BrandDTO): Promise<void> => {
    setBrand(brand);
    setBrandFormOpen(false);
  };

  return (
    <Sidebar collapsible="none" {...props} className="border-r">
      <SidebarHeader>
        <div className="p-8">
          <img src="/logo_dark.svg" alt="Your Logo" className="w-full h-auto" />
        </div>
        <BrandSwitcher
          brands={brandsResponse || []}
          openBrandForm={() => setBrandFormOpen(true)}
        />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
      </SidebarContent>
      <SidebarFooter>
        <div className="px-2 hidden md:block group-data-[collapsible=icon]:hidden">
          <UpgradeCard />
        </div>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />

      <Dialog open={brandFormOpen} onOpenChange={setBrandFormOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New Brand</DialogTitle>
          </DialogHeader>

          {brandFormError && (
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{brandFormError?.message}</AlertDescription>
            </Alert>
          )}

          <BrandForm
            onSuccess={handleBrandFormSuccess}
            onError={setBrandFormError}
          />
        </DialogContent>
      </Dialog>
    </Sidebar>
  );
}
