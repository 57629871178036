import { useEffect, useState } from "react";
import { useBrandContext } from "@/hooks";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

export const UpgradeCard = (): JSX.Element => {
  const { brand } = useBrandContext();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!brand) return;
    if (!brand?.quotas) return;

    if (brand?.quotas?.words?.used >= brand?.quotas?.words?.limit) {
      setIsDisplayed(true);
      return;
    }

    if (!brand?.quotas?.content) return;

    if (
      brand?.quotas?.content?.used >= brand?.quotas?.content?.limit &&
      brand?.quotas?.content?.limit !== -1
    ) {
      setIsDisplayed(true);
      return;
    }

    setIsDisplayed(false);
  }, [brand]);

  if (!brand) {
    return <></>;
  }

  if (!isDisplayed) {
    return <></>;
  }

  return (
    <Card className="bg-gradient-to-br from-violet-500 to-fuchsia-500 p-4 rounded-lg shadow-md">
      <h2 className="text-lg font-bold mb-2 text-white">
        Supercharge Your Content Strategy
      </h2>
      <p className="mb-3 text-xs text-white opacity-90">
        You've maxed out the free tier! Unleash the full potential of your
        content with one of our paid plans.
      </p>
      <Button variant="outline" size="sm" onClick={() => navigate("/upgrade")}>
        Upgrade
      </Button>
    </Card>
  );
};

export default UpgradeCard;
