import { BrandDtoV2 } from "@/dtos";
import { createContext, useState, ReactNode } from "react";

export interface BrandContextType {
  brand: BrandDtoV2 | null;
  setBrand: (brand: BrandDtoV2 | null) => void;
}

export const BrandContext = createContext<BrandContextType | undefined>(
  undefined
);

export interface BrandProviderProps {
  children: ReactNode;
}

export const BrandProvider = ({ children }: BrandProviderProps) => {
  const [brand, setBrand] = useState<BrandDtoV2 | null>(null);

  return (
    <BrandContext.Provider value={{ brand, setBrand }}>
      {children}
    </BrandContext.Provider>
  );
};
