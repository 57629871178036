import { useState } from "react";
import {
  TypographyH3,
  TypographyH4,
  WriterCard,
  WriterFormV2,
} from "@/components";
import { useBrandContext } from "@/hooks";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { WriterDto, WriterTone } from "@/dtos/writers";
import { useAuth } from "@/hooks/use-auth";
import axios from "axios";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { ContentType } from "@/dtos";
import { Text } from "@radix-ui/themes";
import { PenTool } from "lucide-react";
import { useGetWritersQuery } from "@/redux";

export const WritersContainer = () => {
  const { token, user } = useAuth();
  const { brand } = useBrandContext();
  const { data: writers, refetch: reloadWriters } = useGetWritersQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand?.id || !user,
    }
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [writerToDelete, setWriterToDelete] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleAddWriter = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = async (values: {
    daysOfWeek: string[];
    hoursOfDay: number[];
    minutesOfHour: number[];
    contentType: ContentType;
    tone?: WriterTone;
  }) => {
    // Handle form submission
    try {
      const { status } = await axios.post(
        `${import.meta.env.VITE_API_V2_URL}/brands/${brand?.id}/writers`,
        {
          ...values,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (status === 201) {
        setIsDialogOpen(false);
        reloadWriters();
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Something went wrong, please try again."
      );
    }
  };

  const handlePauseWriter = async (id: string) => {
    try {
      const writer = writers?.find((writer: WriterDto) => writer.id === id);
      if (!writer) {
        throw new Error("Writer not found");
      }
      const { status } = await axios.patch(
        `${import.meta.env.VITE_API_V2_URL}/brands/${brand?.id}/writers/${id}`,
        {
          ...writer,
          isActive: false,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (status === 200) {
        reloadWriters();
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Something went wrong, please try again."
      );
    }
  };

  const handleResumeWriter = async (id: string) => {
    try {
      const writer = writers?.find((writer: WriterDto) => writer.id === id);
      if (!writer) {
        throw new Error("Writer not found");
      }
      const { status } = await axios.patch(
        `${import.meta.env.VITE_API_V2_URL}/brands/${brand?.id}/writers/${id}`,
        {
          ...writer,
          isActive: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (status === 200) {
        reloadWriters();
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Something went wrong, please try again."
      );
    }
  };

  const handleDeleteWriter = async (id: string) => {
    setWriterToDelete(id);
    setIsConfirmDialogOpen(true);
  };

  const confirmDeleteWriter = async () => {
    if (!writerToDelete) return;

    try {
      const { status } = await axios.delete(
        `${import.meta.env.VITE_API_V2_URL}/brands/${
          brand?.id
        }/writers/${writerToDelete}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (status === 204) {
        reloadWriters();
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Something went wrong, please try again."
      );
    } finally {
      setIsConfirmDialogOpen(false);
      setWriterToDelete(null);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between pb-7">
        <TypographyH3>Writers</TypographyH3>
        {writers && writers.length > 0 && (
          <Button onClick={handleAddWriter}>Add a Writer</Button>
        )}
      </div>
      {writers && writers.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {writers.map((writer: WriterDto) => (
            <WriterCard
              key={writer.id}
              writer={writer}
              onPause={handlePauseWriter}
              onDelete={handleDeleteWriter}
              onResume={handleResumeWriter}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-1 flex-col items-center justify-center gap-6 text-center p-8 h-[80%]">
          <PenTool size={64} className="text-blue-400" />
          <div>
            <h3 className="text-3xl font-bold tracking-tight mb-2">
              No Writers Yet
            </h3>
            <Text size="3" className="text-gray-600 max-w-md mx-auto">
              Start your content creation journey by adding your first writer.
              Each writer can be customized to create specific types of content
              on a schedule that works for you!
            </Text>
          </div>
          <div className="space-y-4">
            <Button onClick={handleAddWriter}>New Writer</Button>
          </div>
        </div>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <TypographyH4>Create Writer</TypographyH4>
          {error && (
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <WriterFormV2 onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this writer? This action cannot be
            undone.
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsConfirmDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={confirmDeleteWriter}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
