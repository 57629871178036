import { ContentType } from "../content";

export enum WriterTone {
  CASUAL = "casual",
  PROFESSIONAL = "professional",
  FORMAL = "formal",
  FRIENDLY = "friendly",
  AUTHORITATIVE = "authoritative",
  HUMOROUS = "humorous",
  INFORMATIVE = "informative",
  PERSUASIVE = "persuasive",
  CONVERSATIONAL = "conversational",
  TECHNICAL = "technical",
}

export interface WriterDto {
  id: string;
  createdAt: string;
  updatedAt: string;
  daysOfWeek: string[];
  hoursOfDay: number[];
  minutesOfHour: number[];
  contentType: ContentType;
  isActive: boolean;
  name: string;
  tone: WriterTone;
  avatar: string;
}
