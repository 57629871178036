import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import socketService from "../services/socket.service";
import { LegacyContentVersionDto } from "@/dtos";
import { AppThunk, RootState } from "../store";

interface EditorState {
  connected: boolean;
  saving: boolean;
  lastSaved: { order: number; date: string | null }[] | null;
  content: { bodyHtml: string; bodyMarkdown: string; order: number }[];
}

const initialState: EditorState = {
  connected: false,
  saving: false,
  lastSaved: null,
  content: [],
};

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    setSaving: (state, action: PayloadAction<boolean>) => {
      state.saving = action.payload;
    },
    setLastSaved: (
      state,
      action: PayloadAction<{ order: number; date: string } | null>
    ) => {
      if (!action.payload) {
        state.lastSaved = null;
        return;
      }
      if (state.lastSaved) {
        const existingIndex = state.lastSaved.findIndex(
          (item) => item.order === action.payload!.order
        );
        if (existingIndex !== -1) {
          state.lastSaved[existingIndex] = action.payload;
        } else {
          state.lastSaved.push(action.payload);
        }
      } else {
        state.lastSaved = [action.payload];
      }
    },
    setContent: (
      state,
      action: PayloadAction<
        { bodyHtml: string; bodyMarkdown: string; order: number }[]
      >
    ) => {
      state.content = action.payload;
    },
  },
});

export const { setConnected, setContent, setSaving, setLastSaved } =
  editorSlice.actions;

export const connectEditor =
  ({ contentId }: { contentId: string }): AppThunk =>
  (dispatch, getState) => {
    const token = (getState() as RootState).auth.token;

    dispatch(setContent([]));
    dispatch(setLastSaved(null));

    socketService.disconnect();

    socketService.connect({ token: token || "" });

    socketService.on("connect", () => {
      socketService.emit("joinContent", { contentId });
      dispatch(setConnected(true));
    });
    socketService.on("disconnect", () => {
      dispatch(setConnected(false));
    });
    socketService.on("content", (content: LegacyContentVersionDto) => {
      dispatch(setContent(content.posts));
    });
  };

export const updateEditorContent =
  (content: { contentId: string; diff: string; post: number }): AppThunk =>
  (dispatch) => {
    dispatch(setSaving(true));
    socketService.emit("updateContent", content);
    dispatch(setSaving(false));
    dispatch(
      setLastSaved({ order: content.post, date: new Date().toISOString() })
    );
  };

export default editorSlice.reducer;
