import {
  ContentGeneratingComponent,
  ContentIdeaComponent,
  ContentResearchingComponent,
  ContentVersionForm,
  TypographyH1,
  TypographyH4,
  TypographyP,
} from "@/components";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useBrandContext, useNotificationsContext } from "@/hooks";
import { Flex } from "@radix-ui/themes";
import { Download, RocketIcon } from "lucide-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BlogThumbnail } from "@/components/blog-thumbnail";
import { AxiosError } from "axios";
import { ApproveContainer } from "@/containers/approve";
import { useGetBrandByIdQuery, useGetContentByIdQuery } from "@/redux";
import { EditorContainer } from "@/containers";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

export const ContentDetails = (): JSX.Element => {
  const { brandId, contentId } = useParams();
  const { addNotification } = useNotificationsContext();
  const { data: brand } = useGetBrandByIdQuery(
    { id: brandId! },
    { skip: !brandId }
  );
  const { setBrand } = useBrandContext();
  const { data, isFetching, refetch } = useGetContentByIdQuery(
    { brandId: brandId!, contentId: contentId! },
    {
      skip: !brandId || !contentId,
    }
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (
      data?.content?.status === "researching" ||
      data?.content?.status === "generating" ||
      data?.content?.status === "generating_images"
    ) {
      interval = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [data?.content?.status, refetch]);

  useEffect(() => {
    if (!brand) return;
    setBrand(brand.brand);
  }, [setBrand, brand]);

  const onNewVersionError = (error: Error) => {
    if (error instanceof AxiosError) {
      addNotification({
        message: error.response?.data.message,
      });
    } else {
      addNotification({
        message: error.message,
      });
    }
  };

  const handleHtmlExport = () => {
    const html = data?.content.versions[0]?.posts[0]?.bodyHtml;
    if (!html) return;
    const blob = new Blob([html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data?.content.versions[0]?.title}.html`;
    a.click();
  };

  const handleMarkdownExport = () => {
    const markdown = data?.content.versions[0]?.posts[0]?.bodyMarkdown;
    if (!markdown) return;
    const blob = new Blob([markdown], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data?.content.versions[0]?.title}.md`;
    a.click();
  };

  const isIdea = data?.content.status === "idea";
  const isResearching = data?.content.status === "researching";
  const isGenerating = data?.content.status === "generating";
  const isGeneratingImages = data?.content.status === "generating_images";
  const isDraft = data?.content.status === "draft";
  const isFailedResearch = data?.content.status === "research_failed";
  const isFailedImages = data?.content.status === "images_failed";
  const isFailed = data?.content.status === "failed";
  const isFinal = data?.content.status === "final";

  return (
    <div className="flex flex-col overflow-y-auto gap-8">
      {isIdea && (
        <ContentIdeaComponent
          brandId={brandId || ""}
          content={data?.content}
          onNewVersionSuccess={refetch}
          onNewVersionError={onNewVersionError}
        />
      )}

      {isResearching && <ContentResearchingComponent />}

      {isGenerating && <ContentGeneratingComponent />}

      {(!isFetching || isGeneratingImages) &&
        data?.content?.versions &&
        data?.content?.versions.length > 0 &&
        (isGeneratingImages ||
          isFailedImages ||
          isDraft ||
          isFailed ||
          isFinal) && (
          <>
            {isGeneratingImages && (
              <Flex pt="2" pb="7">
                <Alert>
                  <RocketIcon className="h-4 w-4" />
                  <AlertTitle>Heads up!</AlertTitle>
                  <AlertDescription>
                    We're still generating the images for this content! Please
                    check back in a few minutes.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailedResearch && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The research step failed, please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailed && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The text content failed to generate, please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailedImages && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The text content was generated successfully, but the images
                    failed to generate. Please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            <Flex justify="between">
              <TypographyH1>{data?.content?.versions[0]?.title}</TypographyH1>
            </Flex>

            <div className="flex w-full gap-8">
              <div className="flex flex-col w-[2/3]">
                <Flex>
                  <EditorContainer
                    contentId={contentId || ""}
                    editable={isDraft}
                    enableImages={data?.content.type === "blog_post"}
                    key={`${contentId}-${data?.content.status}`}
                  />
                </Flex>
              </div>

              <Card className="flex flex-col w-[1/3] h-fit">
                {/* Blog post specific content */}
                {data?.content.type === "blog_post" && (
                  <>
                    <div className="p-4 border-b border-gray-100">
                      <Flex direction="column" width="100%" gap="3">
                        <TypographyH4 className="text-gray-900">
                          Export
                        </TypographyH4>
                        <Flex gap="2">
                          <Button className="w-full" onClick={handleHtmlExport}>
                            <Flex gap="2" align="center">
                              <Download size={18} />
                              Export as HTML
                            </Flex>
                          </Button>
                          <Button
                            className="w-full"
                            onClick={handleMarkdownExport}
                          >
                            <Flex gap="2" align="center">
                              <Download size={18} />
                              Export as Markdown
                            </Flex>
                          </Button>
                        </Flex>
                      </Flex>
                    </div>
                    <div className="border-b border-gray-100">
                      <BlogThumbnail
                        thumbnail={data?.content?.versions[0]?.thumbnail || ""}
                        generating={isGeneratingImages}
                        failed={isFailedImages}
                      />
                    </div>
                  </>
                )}

                <div className="flex flex-col divide-y divide-gray-100">
                  {/* Meta description for blog posts */}
                  {data?.content.type === "blog_post" && (
                    <div className="p-6">
                      <Flex direction="column" width="100%" gap="3">
                        <TypographyH4 className="text-gray-900">
                          Meta Description
                        </TypographyH4>
                        <TypographyP className="text-gray-600 leading-relaxed">
                          {data?.content?.versions[0]?.meta}
                        </TypographyP>
                      </Flex>
                    </div>
                  )}

                  {/* Content brief */}
                  <div className="p-6">
                    <TypographyH4 className="text-gray-900 mb-3">
                      Content Brief
                    </TypographyH4>
                    <TypographyP className="text-gray-600 leading-relaxed">
                      {data.content.brief}
                    </TypographyP>
                  </div>

                  {/* New version generation form */}
                  {!isFinal && (
                    <div className="p-6">
                      <Flex direction="column" gap="3">
                        <TypographyH4 className="text-gray-900">
                          Generate New Version
                        </TypographyH4>
                        <TypographyP className="text-gray-600">
                          Provide feedback to Marketer AI to generate an
                          improved version.
                        </TypographyP>
                        {data?.content && (
                          <ContentVersionForm
                            brand={{ id: brandId!, name: "" }}
                            content={data?.content}
                            onSuccess={refetch}
                            onError={() => {}}
                            displayLabel={false}
                            buttonText="Generate new version"
                            buttonFullWidth={true}
                            disabled={
                              data?.content?.status !== "draft" &&
                              data?.content?.status !== "images_failed"
                            }
                          />
                        )}
                      </Flex>
                    </div>
                  )}

                  {/* Approval section */}
                  <div className="p-6">
                    {!isFinal ? (
                      <Flex direction="column" gap="4">
                        <ApproveContainer
                          brandId={brandId}
                          contentId={contentId}
                          contentType={data?.content.type}
                          contentStatus={data?.content.status}
                          isApproved={data?.content.isApproved}
                          publishDate={data?.content.targetDate}
                        />
                      </Flex>
                    ) : (
                      <ApproveContainer
                        brandId={brandId}
                        contentId={contentId}
                        contentType={data?.content.type}
                        contentStatus={data?.content.status}
                        isApproved={data?.content.isApproved}
                        publishDate={data?.content.targetDate}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}

      {!isFetching && isFailed && data?.content.versions.length === 0 && (
        <Flex pt="2" pb="7" direction="column" gap="4">
          <Alert variant="destructive">
            <AlertTitle>Failed!</AlertTitle>
            <AlertDescription>
              This content failed to generate. Please try again.
            </AlertDescription>
          </Alert>

          <Flex width="30%">
            <ContentVersionForm
              firstDraft={true}
              brand={{ id: brandId!, name: "" }}
              content={data?.content}
              onSuccess={refetch}
              onError={() => {}}
            />
          </Flex>
        </Flex>
      )}
    </div>
  );
};
