import { baseApi } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ["Whoami"],
});

const whoamiApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getWhoami: builder.query<{ id: string; email: string }, void>({
      query: () => "/auth/whoami",
      providesTags: ["Whoami"],
    }),
  }),
});

export const { useGetWhoamiQuery } = whoamiApi;
