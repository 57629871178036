import {
  format,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { TypographyH3 } from "../typography";
import { CalendarEvent } from "./types";
import { useState } from "react";
import { Button } from "../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Book, Linkedin, Twitter } from "lucide-react";

export interface CalendarMonthComponentProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
}

export const CalendarMonthComponent = ({
  events,
  onEventClick,
}: CalendarMonthComponentProps): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState<"month" | "week">("month");

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const firstDayOfWeek = startOfWeek(currentDate);
  const lastDayOfWeek = endOfWeek(currentDate);

  const daysInMonth = lastDayOfMonth.getDate();
  const startingDayOfWeek = firstDayOfMonth.getDay();

  const goToPreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const goToPreviousWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  const goToNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const getEventIcon = (type: CalendarEvent["type"]) => {
    switch (type) {
      case "blog_post":
        return <Book className="w-4 h-4" />;
      case "linkedin_post":
        return <Linkedin className="w-4 h-4" />;
      case "x_post":
        return <Twitter className="w-4 h-4" />;
    }
  };

  const days = [];

  if (viewMode === "month") {
    for (let i = 0; i < startingDayOfWeek; i++) {
      days.push(
        <div
          key={`empty-${i}`}
          className="h-24 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg opacity-50"
        />
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const dayEvents = events.filter(
        (event) => event.date.toDateString() === date.toDateString()
      );

      const isToday = date.toDateString() === new Date().toDateString();

      days.push(
        <div
          key={day}
          className={`h-24 p-2 rounded-lg transition-all duration-200 hover:transform hover:scale-[1.02] ${
            isToday
              ? "bg-gradient-to-br from-blue-50 to-indigo-50 shadow-lg border-2 border-blue-200"
              : "bg-white shadow-sm hover:shadow-md border border-gray-100"
          }`}
        >
          <div className="flex justify-between items-center">
            <span
              className={`font-semibold ${
                isToday
                  ? "text-blue-600 bg-blue-100 rounded-full w-6 h-6 flex items-center justify-center text-sm"
                  : "text-gray-700"
              }`}
            >
              {day}
            </span>
            {dayEvents.length > 0 && (
              <span className="flex h-2 w-2 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
              </span>
            )}
          </div>
          <div className="mt-1 space-y-1 max-h-14 overflow-y-auto">
            {dayEvents.map((event) => (
              <TooltipProvider key={event.id}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div
                      className="w-full text-xs py-1 rounded-md cursor-pointer transition-colors
                  bg-gradient-to-r from-blue-500 to-indigo-600 text-white
                  hover:from-blue-600 hover:to-indigo-700
                  shadow-sm hover:shadow truncate flex items-center"
                      onClick={() => onEventClick(event)}
                    >
                      <div className="flex items-center gap-1 px-1">
                        {getEventIcon(event.type)}
                        <div className="truncate">
                          {format(event.date, "h:mm a")}
                        </div>
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{event.title}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      );
    }
  } else {
    // Week view with larger cells and more event details
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      const dayEvents = events.filter(
        (event) => event.date.toDateString() === date.toDateString()
      );

      const isToday = date.toDateString() === new Date().toDateString();

      days.push(
        <div
          key={`week-${i}`}
          className={`h-96 p-4 rounded-lg transition-all duration-200 hover:transform hover:scale-[1.02] ${
            isToday
              ? "bg-gradient-to-br from-blue-50 to-indigo-50 shadow-lg border-2 border-blue-200"
              : "bg-white shadow-sm hover:shadow-md border border-gray-100"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <div className="flex flex-col">
              <span
                className={`text-xl font-semibold ${
                  isToday
                    ? "text-blue-600 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center"
                    : "text-gray-700 rounded-full w-10 h-10 flex items-center justify-center"
                }`}
              >
                {date.getDate()}
              </span>
            </div>
            {dayEvents.length > 0 && (
              <span className="flex h-3 w-3 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
              </span>
            )}
          </div>
          <div className="space-y-2 overflow-y-auto h-72">
            {dayEvents.map((event) => (
              <TooltipProvider key={event.id}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div
                      className="w-full p-4 rounded-md cursor-pointer transition-colors
                    bg-gradient-to-r from-blue-500 to-indigo-600 text-white
                    hover:from-blue-600 hover:to-indigo-700
                    shadow-sm hover:shadow min-h-[80px] items-center justify-center flex flex-col"
                      onClick={() => onEventClick(event)}
                    >
                      <div className="text-base font-semibold w-full h-full flex items-center justify-center">
                        {getEventIcon(event.type)}
                      </div>
                      <div className="text-sm mt-2 opacity-90">
                        {format(event.date, "h:mm a")}
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="text-sm">{event.title}</div>
                    <div className="text-xs">{format(event.date, "PPp")}</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col gap-4 w-full h-full bg-white rounded-xl overflow-auto">
      <div className="flex justify-between items-center sticky top-0 bg-white z-10 p-2">
        <div className="flex items-center gap-4">
          <TypographyH3>
            {viewMode === "month"
              ? format(firstDayOfMonth, "MMMM yyyy")
              : `${format(firstDayOfWeek, "MMM d")} - ${format(
                  lastDayOfWeek,
                  "MMM d, yyyy"
                )}`}
          </TypographyH3>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              onClick={
                viewMode === "month" ? goToPreviousMonth : goToPreviousWeek
              }
            >
              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
            </Button>
            <Button
              variant="outline"
              onClick={viewMode === "month" ? goToNextMonth : goToNextWeek}
            >
              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
            </Button>
          </div>
          <Select
            value={viewMode}
            onValueChange={(value: "month" | "week") => setViewMode(value)}
          >
            <SelectTrigger className="w-32">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="month">Month</SelectItem>
              <SelectItem value="week">Week</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-2 px-2 pb-2">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="h-8 flex items-center justify-center font-medium text-gray-400 text-sm tracking-wider sticky top-16 bg-white z-10"
          >
            {day}
          </div>
        ))}
        {days}
      </div>
    </div>
  );
};
