export interface ImageDto {
  id: string;
  createdAt: Date;
  url: string;
}

export enum ContentType {
  BLOG_POST = "blog_post",
  X_POST = "x_post",
  LINKEDIN_POST = "linkedin_post",
}

export interface ContentVersionDto {
  id: string;
  createdAt: Date;
  posts: {
    bodyMarkdown: string;
    bodyHtml: string;
    order?: number;
    title?: string;
    meta?: string;
    slug?: string;
    thumbnail?: string;
    images?: ImageDto[];
  }[];
}

export interface LegacyContentVersionDto {
  id: string;
  createdAt: Date;
  title?: string;
  meta?: string;
  posts: { bodyHtml: string; bodyMarkdown: string; order: number }[];
  body: string | { posts: { content: string; order: number }[] };
  bodyHtml: string;
  thumbnail?: string;
  images?: string[];
}

export interface ContentDto {
  id: string;
  brandId: string;
  type: ContentType;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  brief: string;
  title: string;
  targetDate: Date;
  isApproved: boolean;
  versions: ContentVersionDto[];
}

export interface LegacyContentDto {
  id: string;
  type: ContentType;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  brief: string;
  title: string;
  targetDate: Date;
  isApproved: boolean;
  versions: LegacyContentVersionDto[];
}
